import React, { useState, useEffect } from "react";
import { leaderBoard } from "../services/api";
import { useNavigate } from "react-router-dom";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi";
function LeaderBoard() {
  let [leaderBoardList, setLeader] = useState([]);
  let [totalPage, setTotalPage] = useState(1);
  let [page, setPage] = useState(1);

  const incPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
  };
  const decPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const firstPage = () => {
    setPage(1);
  };
  const lastPage = () => {
    setPage(totalPage);
  };

  useEffect(() => {
    const fetchGames = async () => {
      let gamesData = await leaderBoard(page);
      const leader = gamesData.data.sort((a, b) => {
      return b.total_points-a.total_points
      })
      setLeader(leader);
      setTotalPage(gamesData.total_pages);
    };
    fetchGames();
  }, [page]);

  const navigate = useNavigate();
  const handleClick = (predict) => {
    navigate("/predicts", { state: { predict } });
  };
  //=====================
  return (
    <div className="col-md-3  py-2 px-3 " style={{ backgroundColor: "maroon" }}>
      <div style={{ width: "285px" }} className="row w-100 mx-auto my-3">
        <div className="w-25 text-center">
          <HiChevronDoubleLeft color="white" onClick={() => firstPage()} />
        </div>
        <div className="w-25 text-center">
          <HiChevronLeft color="white" onClick={() => decPage()} />
        </div>
        <div className="w-25 text-center">
          <HiChevronRight color="white" onClick={() => incPage()} />
        </div>
        <div className="w-25 text-center">
          <HiChevronDoubleRight color="white" onClick={() => lastPage()} />
        </div>
      </div>
      <table className="table table-striped table-bordered bdr">
        <thead className="thead-dark">
          <tr>
            <th scope="col">No</th>
            <th scope="col">User Name</th>
            <th scope="col">Points</th>
          </tr>
        </thead>
        <tbody>
          {leaderBoardList.map((predict, i) => {
            return (
              <tr
                key={i}
                onClick={() => {
                  handleClick(predict);
                }}
              >
                <td align="center">{i + 1 + (page - 1) * 25}</td>
                <td>{predict.username}</td>
                <td align="center">{predict.total_points}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default LeaderBoard;
