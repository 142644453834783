import React, { useEffect, useState } from "react";
import { userPredictions } from "../services/api";
import Cookies from "universal-cookie";

function UserPoinrs() {
  const cookies = new Cookies();
  const username = cookies.get("username");
  let [userPres, setUserPredictions] = useState([]);
  let [userPoints, setUserPoints] = useState(0);
  let [userRank, setUserRank] = useState(0);

  useEffect(() => {
    const fetchGames = async () => {
      let getData = await userPredictions();
      setUserPoints(getData.points);
      setUserPredictions(getData.predictions);
      setUserRank(getData.rank);
    };
    fetchGames();
  });
  return (
    <div className="col-md-3 mx-2 p-2 " style={{ backgroundColor: "maroon" }}>
      <div className=" justify-content-between d-flex flex-wrap text-white p-3">
        <h5>{username} Total points: {userPoints ?? 0}</h5>
        {/* <h5>Rank: {userRank}</h5> */}
      </div>

      {userPres.map((userPre) => {
        return (
          <div className="card m-4 p-3">
            <table>
              <thead>
                <tr>
                  <th>Team</th>
                  <th></th>
                  <th>Res.</th>
                  <th>Pred.</th>
                  <th>Pts.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width={100}>{userPre.game.first_team}</td>
                  <td>
                    <img
                      width={35}
                      src={require("../assets/images/" +
                        userPre.game.first_team +
                        ".png")}
                    />
                  </td>
                  <td align="center">{userPre.game.first_team_goals}</td>
                  <td align="center">{userPre.first_team_predict}</td>
                  <td colSpan={2} valign="middle">
                    {userPre.points}
                  </td>
                </tr>
                <tr>
                  <td>{userPre.game.second_team}</td>
                  <td>
                    <img
                      width={35}
                      src={require("../assets/images/" +
                        userPre.game.second_team +
                        ".png")}
                    />
                  </td>
                  <td align="center">{userPre.game.second_team_goals}</td>
                  <td align="center">{userPre.second_team_predict}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export default UserPoinrs;
