import "./App.css";
import Cookies from "universal-cookie";
import Auth from "./pages/Auth";
import Home from "./pages/Home";

const cookies = new Cookies();
let isLogined = cookies.get("isLogined");

function MainPage() {
  return isLogined != null && isLogined ? (
    <div className="h-100 bg-body-secondary">
      <Home />
    </div>
  ) : (
    <div className="h-100">
      <Auth />
    </div>
  );
}

export default MainPage;
