import { getGames } from "../services/api";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function Predictions() {
  const username = cookies.get("username");
  const [games, setGames] = useState([]);

  function getCurrentFormattedDate() {
    const now = new Date();
  
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  function parseFormattedDate(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    const milliseconds = Number(timePart.split('.')[1]);
  
    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds));
  }

  useEffect(() => {
    const fetchGames = async () => {
      let gamesData = await getGames();
      setGames(gamesData.data);
    };
    fetchGames();
  }, []);

  const navigate = useNavigate();
  const handleClick = (game) => {
    navigate("/insert", { state: { game } });
  };
  const handleGoalsClick = (game) => {
    navigate("/insertg", { state: { game } });
  };

  // map through games and display them in a table
  return (
    <div
      style={{ backgroundColor: "maroon" }}
      className="col-md-3 mx-2 pt-2 px-3"
    >
      <center className="text-white"><h3>Matches Available</h3></center>
      {games &&
        games.map((game) => {
          return true &&  parseFormattedDate(getCurrentFormattedDate()) <  parseFormattedDate(game.date_time) ? (
            <div key={game.id}>
              <div
                className="card p-3 my-3"
                
                onClick={() => handleClick(game)}
              >
                <div
                  style={{ backgroundColor: "#9B0F32" }}
                  className="card text-white d-flex flex-row justify-content-between px-2 mb-2"
                >
                  <h5>{game.date}</h5>
                  <h5>{game.time}</h5>
                </div>
                {/* Table */}
                <table>
                  <tr>
                    <td className="names">
                      <h5>{game.first_team}</h5>
                    </td>
                    <td>
                      <img
                        width={35}
                        src={require("../assets/images/" +
                          game.first_team +
                          ".png")}
                      />
                    </td>
                    <td align="center">-</td>
                    <td>
                      <img
                        width={35}
                        src={require("../assets/images/" +
                          game.second_team +
                          ".png")}
                      />
                    </td>
                    <td className="right-text">
                      <h5>{game.second_team}</h5>
                    </td>
                  </tr>
                  {/* == */}
                  <tr>
                    <td className="names"></td>
                    <td width={35} align="center">
                      <h5>{game.first_team_predict ?? ""}</h5>
                    </td>
                    <td align="center">-</td>
                    <td width={35} align="center">
                      <h5>{game.second_team_predict ?? ""}</h5>
                    </td>
                    <td className="names"></td>
                  </tr>
                </table>

                {/* Table */}
              </div>
              {username == "Sermed Berwari" ? (
                <div 
                onClick={() => handleGoalsClick(game)}
                className="btn btn-light">Inser Goals</div>
              ) : null}
            </div>
          ) : null;
        })}
    </div>
  );
}
