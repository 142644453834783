import React from "react";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
function Developer() {
  return (
    <div className="text-center">
      <p></p>
      <h5>Developer : Sermed Berwari</h5>
      <a className="btn " href="https://www.facebook.com/sermed.mayi">
        <BsFacebook size={35} color="blue" />
      </a>
      <a className="btn" href="https://www.instagram.com/sermedberwari/">
        <BsInstagram size={35} color="red" />
      </a>
      <a
        className="btn"
        href="https://www.youtube.com/channel/UCj94PszXny110goJ1UmqXjg"
      >
        <BsYoutube size={35} color="red" />
      </a>
<p></p>
      <img className="rounded-circle"
        src={require("../assets/images/sar.jpg")}
        alt=""
        width={75}
      />
    </div>
  );
}

export default Developer;
