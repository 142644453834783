import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import MainPage from "./MainPage";
import Home from "./pages/Home";
import InserdPrediction from "./components/InserdPrediction";
import UserDetails from "./components/UserDetails";
import Predictions from "./components/Predictions";
import LeaderBoard from "./components/LeaderBoard";
import Instructions from "./components/Instructions";
import InsertGoals from './components/InsertGoals'

function App() {
  return (
    <div className="h-100">
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/Edit/:title" element={<Home />}></Route>
          <Route path="/insert" element={<InserdPrediction />}></Route>
          <Route path="/insertg" element={<InsertGoals />}></Route>
          <Route path="/predicts" element={<UserDetails />}></Route>
          <Route path="./prediction" element={<Predictions/>}></Route>
          <Route path="./leaderBoard" element={<LeaderBoard/>}></Route>
          <Route path="./instructions" element={<Instructions/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
