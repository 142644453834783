import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
function UserDetails(props) {
  const location = useLocation();
  const { predict } = location.state;

  function getCurrentFormattedDate() {
    const now = new Date();
  
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  function parseFormattedDate(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    const milliseconds = Number(timePart.split('.')[1]);
  
    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds));
  }

  return (
    <div className="col-md-3 mx-2 pt-2 " style={{ backgroundColor: "maroon" }}>
      <div className="text-center ">
        <h5 className="text-white">{predict.username}'s Predictions</h5>
      </div>
      {predict.predicts.map((userPre) => {
        
        return parseFormattedDate(getCurrentFormattedDate()) >  parseFormattedDate(userPre.date_time) ? (
          <div className="card m-4 p-3">
            <table>
              <thead>
                <tr>
                  <th>Team</th>
                  <th></th>
                  <th>Res.</th>
                  <th>Pred.</th>
                  <th>Pts.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width={100}>{userPre.first_team}</td>
                  <td>
                    <img
                      width={35}
                      src={require("../assets/images/" +
                        userPre.first_team +
                        ".png")}
                    />
                  </td>
                  <td align="center">{userPre.first_team_goals}</td>
                  <td align="center">{userPre.first_team_predict}</td>
                  <td colSpan={2} valign="middle">
                    {userPre.points}
                  </td>
                </tr>
                <tr>
                  <td>{userPre.second_team}</td>
                  <td>
                    <img
                      width={35}
                      src={require("../assets/images/" +
                        userPre.second_team +
                        ".png")}
                    />
                  </td>
                  <td align="center">{userPre.second_team_goals}</td>
                  <td align="center">{userPre.second_team_predict}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null;
      })}
    </div>
  );
}

export default UserDetails;
