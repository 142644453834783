import React, { useState } from "react";
import { newUser } from "../../services/api";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function Register() {
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [isError, setError] = useState("false");

  const timestamp = new Date().getTime();
  const expire = timestamp + 60 * 60 * 24 * 1000 * 365;
  const expireDate = new Date(expire);

  async function registerWithData(e) {
    e.preventDefault();
    let loginData = {
      email: email,
      password: password,
      username: username,
      phone: phone,
    };
    let userData = await newUser(loginData);
    if (userData.status === "Login Succeed") {
      cookies.set("username", userData.username, {
        expires: expireDate,
      });
      cookies.set("isLogined", true, {
        expires: expireDate,
      });
      cookies.set("token", userData.token, {
        expires: expireDate,
      });
      setError(false);
      window.location.reload(true);
    } else {
      setError(true);
    }
  }
  return (
    <React.Fragment>
      <h5 className="text-center ">تسجيل الدخول إلى حسابك</h5>

      <form onSubmit={registerWithData}>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword2" className="form-label">
            UserName
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputPassword2"
            name="username"
            required
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            name="email"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            name="password"
            required
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword3" className="form-label">
            Phone Number
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputPassword3"
            name="phone"
            required
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        {isError === true && (
          <div className="col-md-4 col-sm-12 w-100 mt-2">
            <div className="alert alert-danger" role="alert">
              User Name or Passwod is wrong
            </div>
          </div>
        )}
      </form>
    </React.Fragment>
  );
}
