import React from 'react';


function Instructions() {
    return (
        <div dir='rtl' className='col-md-3 mx-2 p-3 bg-white' style={{color:"maroon",fontWeight:"bold"}}>
            <table>
                <tr>
                    <td align='center' colSpan={2}><h4>چاوانییا ھژمارتنا خالان</h4><p></p></td>
                </tr>
                <tr>
                    <td valign='top'>1-</td>
                    <td style={{textAlign:"justify"}}>پیَشبینیا درست بو برنا تیمەكێ ب سێ خالانە، ئەگەر تە زانی كیژ تیم دێ بەت یان تە زانی دێبیتە وەكھەڤی ب سێ خالانە<p></p></td>
                </tr>
                <tr>
                    <td  valign='top'>2-</td>
                    <td style={{textAlign:"justify"}}>بو ھەر تیمەكێ، ئەگەر تە ژمارا گولێن ئەو تیم بكەت درست دەركەت ب دوو خالانە<p></p></td>
                </tr>
                <tr>
                    <td valign='top'>3-</td>
                    <td style={{textAlign:"justify"}}>بو ھەر تیمەكێ، ئەگەر تە ژمارا گولێن ئەو تیم بكەت كێمتربو یان زێدەتربو ب گولەكێ دێ ئێك خال ب دەست خوڤە ئینی<p></p></td>
                </tr>
                <tr>
                    <td valign='top'>4-</td>
                    <td style={{textAlign:"justify"}}>بو یاریێن قوناغا دەركتنا تاك، ئەنجامێن لێدانێن سزاییێ نا ھێنە پێشبینی كرن، پێشبینی بتنێ بو 120 خولەكێن یاریێنە <p></p></td>
                </tr>
                <tr>
                    <td valign='top'>5-</td>
                    <td style={{textAlign:"justify"}}>نمونە : ئەگەر یاریەكێ تە پێشبینی كر 2-1 بو تیما A ویاری ب دوماھی ھات ب وەكھەڤی 2-2، پێشبینیا تە خەلەت دەركەت بو برنا تیمەكێ چ خال ناھێنە وەرگرتن، بو تیما A تەپێشبینی كربو 2 گولا بكەت درست دەركەت دێ 2 خالابەی، و بو تیما دی تە پێشبینی كربو گولەك و ئەو ب خو 2 گولبون، چونكی كێمترە ب گولەكێ دێخالەكێ بەی، ھەمی دێ بنە سێ خال.<p></p></td>
                </tr>
            </table>
        </div>
    );
}

export default Instructions;