import Instructions from "../components/Instructions";
import LeaderBoard from "../components/LeaderBoard";
import Predictions from "../components/Predictions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import UserPoints from "../components/UserPoints"

export default function Home() {
    return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Home</Tab>
          <Tab>Ranking</Tab>
          <Tab>Instr.</Tab>
          <Tab>Profile</Tab>
        </TabList>

        <TabPanel>
          <Predictions />
        </TabPanel>
        <TabPanel>
          <LeaderBoard />
        </TabPanel>
        <TabPanel>
          <Instructions />
        </TabPanel>
        <TabPanel>
          <UserPoints />
        </TabPanel>
      </Tabs>
    </div>
  );
}
