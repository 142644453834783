import axios from "axios";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("token");
export async function login(loginInfo) {
  let url = "https://euro.mubatech.com/api/v1/login";
  let data = await axios.post(url, loginInfo);
  return data.data;
}

export async function newUser(loginInfo) {
  let url = "https://euro.mubatech.com/api/v1/newUser";
  let data = await axios.post(url, loginInfo);
  return data.data;
}

export async function getGames() {
  let url = "https://euro.mubatech.com/api/v1/getGamesWithPredictions";
  let data = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  //console.log(data.data)
  return data.data;
}

export async function insertPredictions(predictions) {
  //console.log(predictions)
  let url = "https://euro.mubatech.com/api/v1/insertPredict";
  let data = await axios.post(url, predictions, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
}

export async function insertGoals(goals) {
  //console.log(predictions)
  let url = "https://euro.mubatech.com/api/v1/insertGoals";
  console.log({
    link: url,
    data: goals,
  });
  let data = await axios.post(url, goals,{
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
}

export async function leaderBoard(page = 1) {
  let url = `https://euro.mubatech.com/api/v1/leaderBoard?page=${page}`;
  let data = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
}

export async function userPredictions() {
  let url = `https://euro.mubatech.com/api/v1/UserPredictions`;
  let data = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  //console.log(data.data)
  return data.data;
}
