import { useState } from "react";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import Developer from "../components/Developer";
export default function Auth() {
  const [haveAcount, setHaveAcount] = useState(false);

  function changeHaveAcount() {
    setHaveAcount(!haveAcount);
  }

  return (
    <div className="h-100 w-100 mx-0 px-1 row d-flex col-12 mt-5">
      <div className="col-lg-4  col-md-6 col-sm-12  m-auto py-3 px-3 rounded-4 shadow-lg ">
        {!haveAcount ? <Login /> : <Register />}
        
        <div className="text-center w-100">
          {!haveAcount ? (
            <div className="mt-2">
              <span>Don't Have Account ?</span>
              <strong className="text-danger" onClick={changeHaveAcount}>
                {" "}
                Register Here
              </strong>
            </div>
          ) : (
            <div className="mt-2">
              <span>Have Account ?</span>
              <strong className="text-danger" onClick={changeHaveAcount}>
                {" "}
                Login Here
              </strong>
              <strong> Register Here</strong>
            </div>
          )}
          <Developer/>
          
        </div>
      </div>
    </div>
  );
}
