import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { insertGoals } from "../services/api";
import Developer from "./Developer";
function InsertGoals() {
  const navigate = useNavigate();
  const location = useLocation();
  const { game } = location.state;
  const [firstTeamGoals, setFirstTeamGoals] = useState(
    game.first_team_goals ?? ""
  );
  const [secondTeamGoals, setSecondTeamGoals] = useState(
    game.second_team_goals ?? ""
  );

  return (
    <div
      className="d-flex justify-content-center align-items-center middle-page "
      style={{ backgroundColor: "maroon" }}
    >
      <div className="card p-3 m-2">
        <form onsubmit="event.preventDefault()">
          <table width={280}>
            <tr>
              <td className="names">
                <h5>{game.first_team}</h5>
              </td>
              <td>
                <img
                  width={35}
                  src={require("../assets/images/" + game.first_team + ".png")}
                />
              </td>
              <td align="center">-</td>
              <td>
                <img
                  width={35}
                  src={require("../assets/images/" + game.second_team + ".png")}
                />
              </td>
              <td className="right-text">
                <h5>{game.second_team}</h5>
              </td>
            </tr>
            {/* == */}
            <tr>
              <td className="names"></td>
              <td width={35} align="center">
                <input
                  className="input-size"
                  type="text"
                  name="firstTeamPre"
                  id=""
                  defaultValue={firstTeamGoals}
                  onChange={(e) => {
                    setFirstTeamGoals(e.target.value);
                  }}
                />
              </td>
              <td align="center">-</td>
              <td width={35} align="center">
                <input
                  className="input-size"
                  type="text"
                  name="secondTeamPre"
                  id=""
                  defaultValue={secondTeamGoals}
                  onChange={(e) => {
                    setSecondTeamGoals(e.target.value);
                  }}
                />
              </td>
              <td className="names"></td>
            </tr>
            <tr>
              <td align="center" colSpan={5}>
                <div
                  onClick={() => {
                    insertGoals({
                      game_id: game.game_id,
                      first_team_goals: firstTeamGoals,
                      second_team_goals: secondTeamGoals,
                    });
                    navigate(-1);
                  }}
                  className="mt-2 btn btn-danger"
                >
                  {" "}
                  Update
                </div>
              </td>
            </tr>
          </table>
        </form>
      <Developer />
      </div>

    </div>
  );
}

export default InsertGoals;
